import React from "react"

import SEO from "../components/seo"
import Layout from "../components/layout"
import DefaultLayoutSidebar from "../components/DefaultLayoutSidebar"
import CustomNavTitle from "../components/CustomNavTitle"

import illustration2x from "../images/illustrations/illustration-ppi@2x.png"

import { theme } from "../styles/theme"

const PositivePatientIdentification = ({ location }) => {
  return (
    <Layout>
      <SEO title="Positive Patient Identification" />

      <CustomNavTitle
        title="Positive Patient Identification"
        destination="/"
        location={location}
      />

      <DefaultLayoutSidebar>
        <section className="theme max-w-content">
          <p css={theme.components.title}>
            These steps should be completed, and any errors or omissions
            rectified before proceeding.
          </p>
          <ul className="spaced mt-gap-2">
            <li>
              A patient identification band (or risk assessed equivalent) must
              be worn by all patients receiving a transfusion.
            </li>
            <li>
              Positive patient identification, using the patient identification
              band, is essential at all stages of the blood transfusion process.
            </li>
            <li>
              Patient core identifiers are: Last name, first name, date of birth
              and unique identification number(s).
            </li>
            <li>
              All patients should, wherever possible, be asked to state their
              full name and date of birth as a minimum.
            </li>
            <li>
              For patients who are unable to identify themselves, local policies
              should be followed to ensure correct identification.
            </li>
            <li>
              All spelling and number sequences on all documentation must match
              exactly the information on the patient's identification band.
              Simple errors can lead to incorrect patient identification and/or
              component selection.
            </li>
          </ul>
        </section>
        <aside>
          <img src={illustration2x} alt="Illustration" />
        </aside>
      </DefaultLayoutSidebar>
    </Layout>
  )
}

export default PositivePatientIdentification
